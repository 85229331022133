<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed, ref, watch } from 'vue';

const page = usePage();

const showFlash = ref(false);

const flash = computed(function () {
    return page.props.flash;
});

const modal = ref(false);

const getIcon = () => {
    let message = page.props.flash.message;
    if (message?.success) {
        return 'fas fa-check-circle';
    } else if (message?.error) {
        return 'fas fa-times-circle';
    } else if (message?.info) {
        return 'fas fa-info-circle';
    } else if (message?.warning) {
        return 'fas fa-exclamation-circle';
    }
};

const getMessage = () => {
    let message = page.props.flash.message;
    if (message?.success) {
        return message?.success;
    } else if (message?.error) {
        return message?.error;
    } else if (message?.info) {
        return message?.info;
    } else if (message?.warning) {
        return message?.warning;
    }
};

watch(() => page.props, function (val) {
    if (val?.flash) {
        showFlash.value = true;

        if(val?.flash.message?.success){
            modal.value= true;
        }
    }
}, {
    immediate: true,
    deep: true,
});


</script>

<template>
    <div v-if="flash.message">
        <v-alert v-if="flash.message?.success" class="mb-5 mt-5" border="start" variant="tonal" type="success"
            :text="flash.message?.success"></v-alert>
        <v-alert v-if="flash.message?.info" class="mb-5" border="start" variant="tonal" type="info"
            :text="flash.message?.info"></v-alert>
        <v-alert v-if="flash.message?.error" class="mb-5" border="start" variant="tonal" type="error"
            :text="flash.message?.error"></v-alert>
        <v-alert v-if="flash.message?.warning" class="mb-5" border="start" variant="tonal" type="warning"
            :text="flash.message?.warning"></v-alert>
    </div>
    <div v-if="flash.message">
        <v-snackbar v-model="modal" timeout="5000">
            <v-icon color="white" class="border-radius-50 icon-size mr-3" size="x-large">{{ getIcon() }}</v-icon>
            <span class="font-size-16 font-weight-300">
                {{ getMessage() }}
            </span>

            <template v-slot:actions>
                <v-btn class="border-radius-50 bg-primary" color="blue" @click="modal = false" variant="text" size="small" icon="far fa-times">
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<style>

.border-radius-50 {
    border-radius: 50% !important;
}

.v-snackbar__wrapper {
    background-color: rgb(var(--v-theme-navy)) !important;
    color: rgb(var(--v-theme-white)) !important;
}

.v-snackbar__content {
    padding: 20px;
}

</style>
